import { logDecodingError } from "@/helpers/airbrake"
import { Elm } from "@/components/elm/Footer.elm"
import Consent from "@/helpers/consent"
import { pushGa4Event } from "@/helpers/ga4_helper"

const PRIVACY = {
  link: "/privacy",
  text: "Learn more about how Spabreaks.com collects and uses personal data."
}

const CONSENT_COOKIE_NAME = "permConsent"

declare global {
  interface Window {
    footerFlags: Flags
  }
}

export default (): void => {
  const container: HTMLElement | null = document.getElementById("elm-footer")

  if (container) {
    const consent = new Consent(CONSENT_COOKIE_NAME)
    const flags: Flags = {
      ...window.footerFlags,
      currentYear: new Date().getFullYear(),
      showConsentBanner: !consent.isSet(),
      privacy: PRIVACY
    }
    const app = Elm.Footer.init({ node: container, flags: flags })

    app.ports.flagDecodingError.subscribe(() => logDecodingError(flags))
    app.ports.setConsent.subscribe((isConsentGiven: boolean) => {
      consent.set(isConsentGiven)
      pushGa4Event({ event: "set_gdpr_consent", consent_given: isConsentGiven })
    })
  }
}
