import Cookies from "./cookies"
import { IS_PRODUCTION_OR_STAGING } from "./environment"

const EXPIRY = 365 // days

class ConsentCookie {
  constructor(readonly name: string) {}

  set(value: string, expiry: number): void {
    if (IS_PRODUCTION_OR_STAGING) {
      Cookies.set(this.name, value, { expires: expiry, domain: "spabreaks.com" })
    } else {
      Cookies.set(this.name, value, { expires: expiry })
    }
  }

  isSet(): boolean {
    if (Cookies.get(this.name)) {
      return true
    } else {
      return false
    }
  }
}

class Consent {
  readonly permanent: ConsentCookie

  constructor(cookieName: string) {
    this.permanent = new ConsentCookie(cookieName)
  }

  set(value: boolean): void {
    this.permanent.set(value.toString(), EXPIRY)
  }

  isSet(): boolean {
    return this.permanent.isSet()
  }
}

export default Consent
